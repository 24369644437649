.modal {
  @apply fixed;
}

.modal-overlay {
  @apply cursor-pointer;
}

.modal-header {
  @apply sticky top-0 z-10 flex justify-end items-center mb-10 bg-background p-5 rounded-2xl;
}

.modal-header button {
  @apply m-0 p-0;
}

.modal-content {
  @apply relative max-w-[60vw] mx-auto bg-background p-5 rounded-2xl;
}

.close {
  @apply text-2xl text-black cursor-pointer;
}

.download-button {
  @apply text-2xl text-black cursor-pointer bg-none border-none;
}

.modal-media {
  @apply max-w-full h-auto;
}

.modal.maximised {
  @apply w-full h-full top-0 left-0 m-0;
}

.modal.maximised .modal-header {
  @apply p-5;
}

.modal.maximised .modal-content {
  @apply m-0 p-5 w-auto h-auto max-w-[80vw] max-h-[80vh] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.modal.maximised .modal-media {
  @apply w-auto h-auto max-w-[80vw] max-h-[80vh] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background p-5 rounded-2xl;
}

.left-chevron,
.right-chevron {
  @apply fixed top-1/2 transform -translate-y-1/2 text-2xl cursor-pointer text-black;
}

.left-chevron {
  @apply left-10;
}

.right-chevron {
  @apply right-10;
}