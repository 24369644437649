.map-container {
    width: 100%;
    height: 70vh;
  }

.pin-content{
  color: black;
  font-size: 14px;
}

.danger { 
  color: red;
  
}

.map-popup-text{
  color:rgba(47, 59, 82, 1)
}