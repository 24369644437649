/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
.react-date-picker {
  @apply inline-flex relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  @apply box-border;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.react-date-picker--disabled {
  @apply bg-[#f0f0f0] text-[#6d6d6d];
}
.react-date-picker__wrapper {
  @apply flex grow shrink-0 border-2 border-secondary rounded-md;
}
.react-date-picker__inputGroup {
  @apply min-w-[calc((4px_*_3)_+_0.54em_*_8_+_0.217em_*_2)] grow box-content px-0.5 py-0;
}
.react-date-picker__inputGroup__divider {
  @apply whitespace-pre px-0 py-px;
}
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  @apply inline-block;
}
.react-date-picker__inputGroup__input {
  @apply min-w-[0.54em] h-1/2 relative text-current box-content px-px py-0 bg-none mt-0.5;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  @apply appearance-none m-0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  @apply ml-[-0.54em] pl-[calc(1px_+_0.54em)];
}
.react-date-picker__button {
  @apply px-1.5 py-1 border-0 enabled:cursor-pointer;
  background: transparent;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  @apply stroke-[#0078d7];
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  @apply stroke-[#6d6d6d];
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  @apply w-[350px] max-w-[100vw] z-[1];
}
.react-date-picker__calendar--closed {
  @apply hidden;
}
.react-date-picker__calendar .react-calendar {
  @apply border-[thin];
}
