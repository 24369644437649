section.detail-section{
@apply flex flex-col gap-4   
}

div.input-card {
    @apply  flex flex-col items-center p-8 shadow rounded-l bg-other;
    }

div.card {
@apply  flex flex-col items-center p-8 transition-colors duration-500 transform shadow cursor-pointer rounded-xl hover:bg-brand bg-other;
}

div.filter-card {
@apply  p-2 transform shadow bg-other;
}
    

/** DRIVER CARD**/


div.driver-card {
@apply  flex flex-col items-center p-8 transition-colors duration-500 transform shadow cursor-pointer rounded-xl hover:bg-brand bg-other;
}

div.driver-card h3 {
@apply mt-4 text-2xl font-semibold capitalize text-primary;
}

div.driver-card p
{
@apply mt-2 capitalize text-primary;
}

div.driver-card .reported-status {
@apply absolute top-0 right-0 inline-flex items-center gap-1 p-1.5 rounded-l-xl rounded-t-md bg-other;
}

div.driver-card img.driver-image 
{
@apply object-cover object-center w-32 h-32 p-1 rounded-full ring-2 ring-primary group-hover:ring-secondary;
}

div.driver-card img.driver-image 
{
    filter: grayscale(100%);
    transition: all 3s ease;
   
}

div.driver-card:hover img.driver-image 
{
    filter: grayscale(0%);
    transition: all 0.75s ease;
}

/** VEHICLE CARD **/

div.vehicle-card {
@apply  relative flex flex-col shadow bg-other;
}

div.vehicle-card h3 {
@apply inline-flex flex-col text-xl font-semibold capitalize text-primary;
}

div.vehicle-card .reported-status {
@apply absolute top-0 right-0 inline-flex items-center gap-1 p-1.5 rounded-l-xl rounded-tl-md bg-other;
}

/**** TABLE (Detail Table) *******/

table.detail-table {
@apply table-auto;
}

table.detail-table > thead
{
@apply text-xs uppercase rounded-sm text-primary bg-background;
}

table.detail-table > tbody
{
@apply text-sm font-normal divide-y divide-background;
}

/***** SERVICE CARD*******/

div.service-card {
@apply relative overflow-hidden rounded-lg;
}

div.service-card h3 {
@apply text-xl font-semibold;
}

/** header button **/

.header-button {
  @apply inline-flex items-center gap-4 px-3 mx-3 text-sm border rounded-md border-brand text-primary hover:text-brand py-1.5
}
/** modal dialog   ***/

.modal-dialog {
  @apply fixed inset-0 z-50 backdrop-blur-sm backdrop-brightness-75 backdrop-contrast-150;
}

/** show on hover button  ***/

.show-hover {
@apply opacity-0 group-hover:opacity-100 transition;
}


/*** scrollbar ***/
.vertical-auto-scrollbar {
    overflow-y: hidden;
  }
  
  .vertical-auto-scrollbar:focus {
    overflow-y: auto;
  }
  
  .vertical-auto-scrollbar:hover {
    overflow-y: auto;
  }

  
/* Width of the scrollbar track */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-other);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-skeleton);
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:active {
    background-color: var(--color-primary);
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-secondary);
  }