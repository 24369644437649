/* Typography */
.h1 {
    @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

/* Buttons */
.btn,
.btn-lg,
.btn-sm,
.btn-xs {
    @apply inline-flex items-center justify-center text-sm font-medium leading-5 transition duration-150 ease-in-out border border-transparent rounded shadow-sm;
}

.btn {
    @apply px-3 py-2;
}

.btn-lg {
    @apply px-4 py-3;
}

.btn-sm {
    @apply px-2 py-1;
}

.btn-xs {
    @apply px-2 py-0.5;
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}




/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* react-datetime custom*/

.rdtPicker {   
    display: none;
    @apply bg-other text-primary p-4 border rounded-sm min-w-[250px] z-50 absolute;
  }

 

  .rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {

    @apply cursor-pointer bg-brand text-white

  }
