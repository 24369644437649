.json-grid-container {
    overflow: auto;
  }
  
  .json-grid-table {
    border-collapse: collapse;
    margin: 10px 0; 
    color: var(--color-primary);
  }
  
  .json-grid-table th,
  .json-grid-table td {
    border: 1px solid #ddd;
    padding: 10px; 
    text-align: left;
  }
  
  .json-grid-table th {
    background-color: var(--color-background);
    font-size: 16px; 
  }
 
  .json-grid-table tbody tr:nth-child(even),
  .json-grid-table tbody tr:nth-child(odd) {
    background-color: var(--color-other);
  }
  
  .json-grid-table tbody tr:hover {
    background-color: var(--color-brand);
  }
  
  .json-grid-title {
    font-size: 20px; 
    margin-bottom: 8px;
    font-weight: bold;
  }
  